<template>
  <div>
    <template v-if="!$route.meta.allowAnonymous">
        <div class="app-container">
          <toolbar :toggle="drawer" :index="index" />
          <v-content class="pl-0 pt-2">
            <router-view v-on:change="change" />
          </v-content>
        </div>

    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view v-on:change.self="change"></router-view>
        </keep-alive>
      </transition>
    </template>
  </div>
</template>

<script>
import Toolbar from "@/components/user/core/Toolbar";

export default {
  components: {
    Toolbar
  },
  data: () => ({
    drawer: true,
    index: 0
  }),
  methods: {
    change(i) {
      // const vm = this
      this.index = i;
    }
  }
};
</script>

<style>
html,
body {
  font-weight: 500;
  font-size: 15px;
  overflow-y: auto;
}
</style>
